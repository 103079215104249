import './App.css';
import './ag-grid.css';
import './kibl-theme.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useState, useEffect, useMemo, useRef } from 'react';
import { CanvasState, KiblCognitoService, CellTypeDisctionary, TypeHeader, TypeFixtureInfo, TypeFixtureInfoTime, TypeOdds } from './kibl-library.js';



class MultiLines {
  init(params) {
    // var timeStamp = params.data.itimestamp;
    var type = params.data.irowtype;
    var iId = params.data.iid;
    var iLId = params.data.ilid;
    var columnName = params.colDef.field;

    var globalId = TypeOdds.name + '-' + iId + '-' + columnName;
    var infos = String(params.value).split('!');
    var newInfo;

    if (type === 'hr') {
      newInfo = new TypeHeader('header', infos[0], true);      
    } else if (type === 'fi') {
      if (columnName.startsWith('b')) {
        let columNameDecoder = String(columnName).split('m');
        let feedSourceId = Number(columNameDecoder[0].replace('b',''));
        let marketTypeId = Number(columNameDecoder[1].replace('m',''));

        newInfo = new TypeOdds(globalId, infos[0], iId, feedSourceId, marketTypeId, columnName, infos[1], infos[2]);

        kiblOddsDictionary.addOrReplaceByGlobalId(newInfo.globalId, newInfo);

      } else if (columnName === 'itime') {
        newInfo = new TypeFixtureInfoTime(globalId, infos[0], infos[1]);
        kiblFixtureInfosDictionary.addOrReplaceByGlobalId(globalId, newInfo);
      } else if (columnName.startsWith('i')) {
        newInfo = new TypeFixtureInfo(globalId, infos[0]);        
        kiblFixtureInfosDictionary.addOrReplaceByGlobalId(globalId, newInfo);
      }
    }

    this.eGui = document.createElement("div");

    var highlight_class = '';
    var highlight_header = false;
    var player_prop_link_start = '';
    var player_prop_link_end = '';

    for (let i = 0; i < newInfo.display_lines.length; i++) {
      var highlight_start = '';
      var highlight_end = '';
      var to_display = '&nbsp;'
      to_display = newInfo.display_lines[i].replaceAll('_', '&nbsp;');

      if (newInfo instanceof TypeOdds) {

        var savedInfo = kiblOddsDictionary.getByGlobalId(newInfo.globalId);

        if (savedInfo !== undefined) {
          newInfo = savedInfo;
        }
        
        if (newInfo.age < 30) {
          highlight_class = '-highlight-level-1';
          highlight_header = true;
        }
        else if (newInfo.age < 60) {
          highlight_class = '-highlight-level-2';
          //highlight_header = true;
        }
        else if (newInfo.age < 90) {
          highlight_class = '-highlight-level-3';
          //highlight_header = true;
        }
      } else if (newInfo instanceof TypeHeader) {
        highlight_start = '<b class=\'header-row\'>';
        highlight_end = '</b>';
      } else if (newInfo instanceof TypeFixtureInfoTime) {
        if ([2, 4].includes(newInfo.stateId)) {
          highlight_class = '-score-in-progress';
          // highlight_start = '<b class=\'score-in-progress\'>';
          // highlight_end = '</b>';
        } else if ([10].includes(newInfo.stateId)) {
          highlight_class = '-score-final';
          // highlight_start = '<b class=\'score-final\'>';
          // highlight_end = '</b>';
        } else if ([3].includes(newInfo.stateId)) {
          highlight_class = '-score-halftime';
          // highlight_start = '<b class=\'score-halftime\'>';
          // highlight_end = '</b>';
        }
      } else if (newInfo instanceof TypeFixtureInfo) { 
        if (columnName === 'irot' && [1, 3, 7, 8, 15].includes(iLId) ) {
          player_prop_link_start = '<a href=\'/?parentFixtureId='+ iId + '&canvasType=player-props&theme=' + kiblOddsCanvasState.theme + '\' target=\'_blank\' class=\'link-player-props\'>';
          // player_prop_link_start = '<a href=\'#\' onclick=\'openPlayerPropsPage(' + iId + ');return false;\'class=\'link-player-props\'>';
          // player_prop_link_start = '<a href=\'#\' this.eGui.onclick=\'javascript:openPlayerPropsPage("hello");\' class=\'link-player-props\'>';
          player_prop_link_end = '</a>';
        }

      }

      var position = 'unknown';

      switch(i) {
        case 0:
          position = 'top';
          break;
        case 1:
          position = 'middle';
          break;
        case 2:
          position = 'bottom'
          break;
        default:
          position = 'bottom'
      }

      
      this.eGui.innerHTML += '<div class=\'ag-kibl-screen-normal-cell\' id=\'' + newInfo.globalId + '-' + position + '\'>' + highlight_start + player_prop_link_start + to_display + player_prop_link_end + highlight_end + '</div>';
    }

    if (String(params.colDef.headerClass).indexOf('highlight') === -1 && highlight_header) {
      params.colDef.headerClass += highlight_class;
      params.api.refreshHeader();
    }

    this.eGui.className = 'ag-kibl-screen-normal-cell' + highlight_class;
    this.eGui.id = newInfo.globalId;

  }

  getGui() {
    return this.eGui;
  }
  refresh() {
    return false;
  }
  destroy() {}
}

// define global variables here
const kiblOddsApiUrl = process.env.REACT_APP_KIBL_ODDS_SERVER_URL;
var kiblOddsAPICanvasUrl = 'bootstrap/canvas'
var kiblOddsAPIColumnDefsUrl = 'bootstrap/column-defs'
var kiblOddsCognito = new KiblCognitoService();
var kiblOddsApiToken = await kiblOddsCognito.getToken();
var kiblOddsCanvasState = new CanvasState();
var kiblOddsDictionary = new CellTypeDisctionary('odds');
var kiblFixtureInfosDictionary = new CellTypeDisctionary('fixture-infos');
var navBarDisplay = 'ag-theme-kibl-nav'
var playerPropBarDisplay = 'ag-theme-kibl-nav-hidden'
var oddsGridApi = null;
var oddsColumnApi = null;
var cronRunner = null;

if (kiblOddsCanvasState.canvasType === 'player-props') {
  kiblOddsAPICanvasUrl = 'bootstrap/canvas-player-props'
  kiblOddsAPIColumnDefsUrl = 'bootstrap/column-defs-player-props'  
  navBarDisplay = 'ag-theme-kibl-nav-hidden'
  playerPropBarDisplay = 'ag-theme-kibl-nav'
}

function useEffectOnce(effect) {
  
  const effectFn = useRef(effect)
  const destroyFn = useRef()
  const effectCalled = useRef(false)
  const rendered = useRef(false)
  const [, refresh] = useState(0)

  if (effectCalled.current) {
    rendered.current = true
  }

  useEffect(() => {
    if (!effectCalled.current) {
      destroyFn.current = effectFn.current()
      effectCalled.current = true
    }

    refresh(1)

    return () => {
      if (rendered.current === false) return
      if (destroyFn.current) destroyFn.current()
    }
  }, [])
}

function composeKiblFetchOptions(kiblToken, payload) {
  kiblOddsCanvasState.saveCanvasStateToLocalStorage();
  var requestOptions = {
    method: 'POST',
    headers: new Headers({'Authorization': kiblToken, 'Accept': '*/*'}),
    body: JSON.stringify(payload)
  };
  return requestOptions;
}

function composeCanvasParameters() {
  // let's init some default parameters if no specific ones are given
  // eventually we'll bootstrap the default init from the server

  const payload = {
    league_id: kiblOddsCanvasState.leagueId,
    fixture_type_id: kiblOddsCanvasState.fixtureTypeId,
    betting_type_id: kiblOddsCanvasState.bettingTypeId,
    segment_id: kiblOddsCanvasState.segmentId,
    market_type_id: kiblOddsCanvasState.marketTypeId,
    show_alternates: kiblOddsCanvasState.showAlternates,
    alt_id: kiblOddsCanvasState.altId,
    start_time: kiblOddsCanvasState.startTime,
    end_time: kiblOddsCanvasState.endTime,
    time_zone: kiblOddsCanvasState.timeZone,
    schedule_range: kiblOddsCanvasState.scheduleRange,
    user_local_time: kiblOddsCanvasState.userLocalTimeISO,
    from_cache: kiblOddsCanvasState.fromCache,
    sport_id: kiblOddsCanvasState.sportId,
    fixture_id: kiblOddsCanvasState.fixtureId,
    parent_fixture_id: kiblOddsCanvasState.parentFixtureId,
    parent_participant_id: kiblOddsCanvasState.parentParticipantId,
    feed_source_id: kiblOddsCanvasState.feedSourceId,
    sort_by: kiblOddsCanvasState.sortByTime ? 'time' : 'rotation',
    show_scores: kiblOddsCanvasState.showScores,
    show_scores_segments: kiblOddsCanvasState.showScoresSegments
    //exclude_informations: 'fixtures-lineups,fixtures-informations,fixtures-states,outcomes'

  };

  return payload;
}

function switchView(myView=false, ...setFilters) {
  /*
  if (myView === false) {
    kiblOddsCanvasState.updateState('sportId', null);
    kiblOddsCanvasState.updateState('currentInMyViews', false);
    kiblOddsCanvasState.updateState('fixtureId', null);
    kiblOddsCanvasState.updateState('segmentId', '1');
    kiblOddsCanvasState.updateState('currentSegmentName', 'FG');
    // kiblOddsCanvasState.updateState('marketTypeId', '0');
    // kiblOddsCanvasState.updateState('currentMarketTypeView', 'm0');
    // kiblOddsCanvasState.updateState('currentMarketName', '');
  }
  */
 
  setFilters.forEach((filter) => {
    var desiredFilter = filter.split("=");
    kiblOddsCanvasState.updateState(desiredFilter[0], desiredFilter[1]);

    if (desiredFilter[0] === 'scheduleRange')
      if (kiblOddsCanvasState.lockToGamesOnly)
        kiblOddsCanvasState.updateState('fixtureTypeId', kiblOddsCanvasState.canvasConstants.gamesOnlyFixtureTypeIds);
      else
        kiblOddsCanvasState.updateState('fixtureTypeId', kiblOddsCanvasState.canvasConstants.allFixtureTypeIds);

      if (desiredFilter[1] === 'yesterday') {
        kiblOddsCanvasState.updateState('startTime', kiblOddsCanvasState.yesterdayStartTimeISO);
        kiblOddsCanvasState.updateState('endTime', kiblOddsCanvasState.defaultStartTimeISO);
      } else if (desiredFilter[1] === 'tomorrow') {
        kiblOddsCanvasState.updateState('startTime', kiblOddsCanvasState.tomorrowStartTimeISO);
        kiblOddsCanvasState.updateState('endTime', kiblOddsCanvasState.tomorrowEndTimeISO);
      } else if (desiredFilter[1] === 'today') {
        kiblOddsCanvasState.updateState('startTime', kiblOddsCanvasState.defaultStartTimeISO);
        kiblOddsCanvasState.updateState('endTime', kiblOddsCanvasState.defaultEndTimeISO);
      } else if (kiblOddsCanvasState.lockToTodayOnly) {
        kiblOddsCanvasState.updateState('startTime', kiblOddsCanvasState.defaultStartTimeISO);
        kiblOddsCanvasState.updateState('endTime', kiblOddsCanvasState.defaultEndTimeISO);
      } else if (desiredFilter[1] === 'upcoming') {
        kiblOddsCanvasState.updateState('startTime', kiblOddsCanvasState.defaultStartTimeISO);
        kiblOddsCanvasState.updateState('endTime', kiblOddsCanvasState.upcomingEndTimeISO);
      }
  });

  refreshView();
  switchMarketType(kiblOddsCanvasState.currentMarketName, kiblOddsCanvasState.currentMarketTypeView);

}

function switchMarketType(currentMarketName, market_type) {

  // oddsGridApi.refreshHeader();

  const allColumns = oddsGridApi.getAllGridColumns();
  var columnsToHide = [];
  var ColumnsToShow = [];

  if (kiblOddsCanvasState.sportId === '6' && market_type === 'm1')
    market_type = 'm7'; // switch to 3-way for soccer
  else if (market_type === 'm7')
    market_type = 'm1';

  allColumns.forEach(element => {
    if (element.colId.includes(market_type))
      ColumnsToShow.push(element.colId);
    else if (element.colId.includes('i')) {
      if (['iprop'].includes(element.colId)) {
        if (kiblOddsCanvasState.canvasType !== 'player-props')
          columnsToHide.push(element.colId);
      } else if (['itime', 'irot'].includes(element.colId)) {
        if (kiblOddsCanvasState.canvasType === 'player-props')
          columnsToHide.push(element.colId);
      } else if (['irowtype', 'ilid', 'iid', 'ipid', 'itimestamp'].includes(element.colId) && kiblOddsCanvasState.showKiblIds === false) {
        columnsToHide.push(element.colId);
      } else if (['iboxscore'].includes(element.colId) && kiblOddsCanvasState.showBoxScores === false) {
        columnsToHide.push(element.colId);
      } else if (!['irowtype', 'ilid', 'itimestamp', 'imid', 'iaid'].includes(element.colId))
        ColumnsToShow.push(element.colId);
    }
    else
      columnsToHide.push(element.colId);

  });

  oddsGridApi.setColumnsVisible(columnsToHide, false);
  oddsGridApi.setColumnsVisible(ColumnsToShow, true);

  kiblOddsCanvasState.updateState('currentMarketTypeView', market_type);
  kiblOddsCanvasState.updateState('currentMarketName', currentMarketName);

  kiblOddsCanvasState.updateBrowserTabTitle();

  // oddsGridApi.refreshHeader();
}

function switchToMyView(viewName) {
  var my_views_config = localStorage.getItem('my_views_config');

  if (my_views_config === null) {
    my_views_config = {
      myViewName: 'Tracking',
      fixtureId: []
    };
    localStorage.setItem('my_views_config', JSON.stringify(my_views_config));
  }
  else
    my_views_config = JSON.parse(my_views_config);

    if (my_views_config.fixtureId.length > 0) {
      switchView(true, 'fixtureId=' + my_views_config.fixtureId.toString(), 'currentTabName=' + my_views_config.myViewName, 'currentInMyViews=true');
  }

}

async function refreshView() {

  const allColumns = oddsGridApi.getAllGridColumns();

  allColumns.forEach(element => {
    element.colDef.headerClass = String(element.colDef.headerClass).replace('-highlight-level-1','').replace('-highlight-level-2','');
  });

  kiblOddsCanvasState.toggleRefreshViewState();
  kiblOddsCanvasState.calculateScopeTimes();
  kiblOddsApiToken = await kiblOddsCognito.getToken();

  oddsGridApi.showLoadingOverlay();

  //fetch(`${kiblOddsApiUrl}bootstrap/canvas`, composeKiblFetchOptions(kiblOddsApiToken, composeCanvasParameters())).then(result => result.json()).then(rowData => oddsGridApi.setGridOption('rowData', rowData)).then(switchMarketType(kiblOddsCanvasState.currentMarketName, kiblOddsCanvasState.currentMarketTypeView));
  await fetch(`${kiblOddsApiUrl}${kiblOddsAPICanvasUrl}`, composeKiblFetchOptions(kiblOddsApiToken, composeCanvasParameters())).then(result => result.json()).then(rowData => oddsGridApi.setGridOption('rowData', rowData)); 

  kiblOddsCanvasState.updateBrowserTabTitle();
  kiblOddsCanvasState.printCurrentStateAsJson();
  kiblOddsCanvasState.toggleRefreshViewState();

  // oddsGridApi.refreshHeader();
}

function toggleOptions(from, ...setFilters) {
  var reloadViewRequired = false;


  setFilters.forEach((filter) => {
    var desiredFilter = filter.split("=");

    if (desiredFilter[0] === 'lockToTodayOnly') {
      if (kiblOddsCanvasState.lockToTodayOnly)
        kiblOddsCanvasState.updateState(desiredFilter[0], false);
      else
        kiblOddsCanvasState.updateState(desiredFilter[0], true);

      reloadViewRequired = true;
    }

    if (desiredFilter[0] === 'sortByTime') {
      if (kiblOddsCanvasState.sortByTime)
        kiblOddsCanvasState.updateState(desiredFilter[0], false);
      else
        kiblOddsCanvasState.updateState(desiredFilter[0], true);

      reloadViewRequired = true;
    }

    if (desiredFilter[0] === 'lockToGamesOnly') {
      if (kiblOddsCanvasState.lockToGamesOnly) {
        kiblOddsCanvasState.updateState(desiredFilter[0], false);
        kiblOddsCanvasState.updateState('fixtureTypeId', kiblOddsCanvasState.canvasConstants.gamesOnlyFixtureTypeIds);
      }
      else {
        kiblOddsCanvasState.updateState(desiredFilter[0], true);
        kiblOddsCanvasState.updateState('fixtureTypeId', kiblOddsCanvasState.canvasConstants.allFixtureTypeIds);
      }
      reloadViewRequired = true;
    }

    if (desiredFilter[0] === 'autoRefresh') {
      if (kiblOddsCanvasState.autoRefresh) {
        kiblOddsCanvasState.updateState(desiredFilter[0], false);
        cronRunner = null;
      }
      else {
        kiblOddsCanvasState.updateState(desiredFilter[0], true);
        cronRunner = setInterval(function() { canvasCrons('autoRefresh')}, 15000);
      }
    }

    if (desiredFilter[0] === 'showAlternates') {
      if (kiblOddsCanvasState.showAlternates) {
        kiblOddsCanvasState.updateState(desiredFilter[0], false);
      }
      else {
        kiblOddsCanvasState.updateState(desiredFilter[0], true);
      }
      reloadViewRequired = true;
    }

    if (desiredFilter[0] === 'theme') {
      
      if (kiblOddsCanvasState.theme === '-dark') {
        kiblOddsCanvasState.updateState(desiredFilter[0], '');
      }
      else {
        kiblOddsCanvasState.updateState(desiredFilter[0], '-dark');
      }

      var new_theme = 'ag-theme-kibl-dark'

      const myDiv = document.getElementById("kibl-canvas");

      myDiv.classList.toggle(new_theme);

      reloadViewRequired = false;

      const links = document.querySelectorAll('a.link-player-props');

      // Loop through the links and make changes
      links.forEach(link => {
        // Change the href attribute
        // link.href = 'https://www.example.com'; 

        // Change the text content
        // link.textContent = 'New Link Text'; 

        link.href = link.href.replace('theme=', '');
        link.href = link.href.replace('-dark', '');
        link.href = link.href + 'theme=' + kiblOddsCanvasState.theme;
      });
      
    }

    if (desiredFilter[0] === 'inGameMode') {

      if (kiblOddsCanvasState.inGameMode) {
        kiblOddsCanvasState.updateState(desiredFilter[0], false);
        kiblOddsCanvasState.updateState('bettingTypeId', kiblOddsCanvasState.canvasConstants.preGameBettingTypeIds);
      }
      else {
        kiblOddsCanvasState.updateState(desiredFilter[0], true);
        kiblOddsCanvasState.updateState('bettingTypeId', kiblOddsCanvasState.canvasConstants.inGameBettingTypeIds);
      }
      reloadViewRequired = true;
    }

    if (desiredFilter[0] === 'showKiblIds') {
      if (kiblOddsCanvasState.showKiblIds) {
        
        if (kiblOddsCanvasState.canvasType === 'player-props')
          oddsGridApi.setColumnsVisible(['irowtype', 'ilid', 'iid', 'ipid', 'imid', 'itimestamp'], false);
        else
          oddsGridApi.setColumnsVisible(['irowtype', 'ilid', 'iid', 'ipid', 'itimestamp'], false);
        
        kiblOddsCanvasState.updateState(desiredFilter[0], false);
      }
      else {
        if (kiblOddsCanvasState.canvasType === 'player-props')
          oddsGridApi.setColumnsVisible(['iid', 'ipid', 'imid'], true);
        else
          oddsGridApi.setColumnsVisible(['iid', 'ipid'], true);
          //oddsGridApi.setColumnsVisible(['iid', 'ipid'], true);
        kiblOddsCanvasState.updateState(desiredFilter[0], true);
      }
    }

    if (desiredFilter[0] === 'showBoxScores') {
      if (kiblOddsCanvasState.showBoxScores) {
        
        oddsGridApi.setColumnsVisible(['iboxscore'], false);
        kiblOddsCanvasState.updateState(desiredFilter[0], false);
      }
      else {
        if (kiblOddsCanvasState.canvasType === 'player-props')
          oddsGridApi.setColumnsVisible([], true);
        else
          oddsGridApi.setColumnsVisible(['iboxscore'], true);
          //oddsGridApi.setColumnsVisible(['iid', 'ipid'], true);
        kiblOddsCanvasState.updateState(desiredFilter[0], true);
      }
    }
  });


  if (reloadViewRequired)
    switchView(false, 'scheduleRange=' + kiblOddsCanvasState.scheduleRange);

  // oddsGridApi.refreshHeader();

  setLinkHilights(from);
}

function canvasCrons(cronname) {

  //const allColumns = oddsGridApi.getAllGridColumns();

  // oddsGridApi.refreshHeader();

  //console.log("here");

  if (kiblOddsCanvasState.autoRefresh && cronname === 'autoRefresh')
    refreshView();

}

function getSharableLink() {
  var link = kiblOddsCanvasState.printSharableLink();
  navigator.clipboard.writeText(link);
  alert('sharable link copied to clipboard!');
}

/*
function openPlayerPropsPage(fixtureId) {
  var link = '/?parentFixtureId='+ fixtureId + '&canvasType=player-props&theme=' + kiblOddsCanvasState.theme

  console.log(link);

}
*/

function clearChanges(individual='', all=false, by_age=false, event) {
  var oddsElements = null;
  if (all)
    oddsElements = document.querySelectorAll('[id^="' + TypeOdds.name + '-"]');
  else if (individual !== '')
    oddsElements = document.querySelectorAll('[id^="' + individual +'"]');


  if (oddsElements !== null) {
    for (let i = 0; i < oddsElements.length; i++) {
      oddsElements[i].className = 'ag-kibl-screen-normal-cell';

      var savedInfo = kiblOddsDictionary.getByGlobalId(oddsElements[i].id);

      if (savedInfo !== undefined) {
        savedInfo.age = 999;
        kiblOddsDictionary.addOrReplaceByGlobalId(savedInfo.globalId, savedInfo);
        savedInfo = kiblOddsDictionary.getByGlobalId(oddsElements[i].id);
      }
    }
  }
}

function setLinkHilights(linkElement) {
  var isSet = false;
  var variableToCheck = null;
  var isHTMLElement = false;
  var unsetClassName = 'ag-kibl-buttons-toggles';
  var setClassName = 'ag-kibl-buttons-toggles-highlight';

  if (typeof linkElement === 'string' || linkElement instanceof String)
    variableToCheck = linkElement;
  else {
    variableToCheck = linkElement.target.id;
    isHTMLElement = true;
  }

  if (variableToCheck !== null) {
    
    isSet = eval('isSet = kiblOddsCanvasState.' + variableToCheck +';');

    if (isSet) {
      if (isHTMLElement)
        linkElement.target.className = setClassName;
      return setClassName;

    } else {
      if (isHTMLElement)
        linkElement.target.className = unsetClassName;
      return unsetClassName;
    }
  }
}

function App() {
  var theme = `ag-theme-kibl${kiblOddsCanvasState.theme}`;
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);  
  const playerPropNavStyle = useMemo(() => ({ width: '100%', background: '#2e547a', color: '#fd050d'}), []);
  const playerPropToggles = useMemo(() => ({ background: '#2e547a'}), []);
  const segmentMarketNavStyle = useMemo(() => ({ width: '100%', background: '#2e547a', color: '#fd050d'}), []);
  const sportNavStyle = useMemo(() => ({ className: 'ag-theme-kibl-nav-hidden', width: '100%',  background: '#052a4e', color: '#fd050d'}), []);
  const favoNavStyle = useMemo(() => ({ className: `${navBarDisplay}`, width: '100%', background: '#4c6986', color: '#fd050d'}), []);
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([]);  
  const [rowData, setRowData] = useState([]);
  const defaultColDef = useMemo( ()=> ({ sortable: false, filter: true, cellRenderer: MultiLines}), []);
  const onGridReady = (params) => { oddsGridApi = params.api; oddsColumnApi = params.columnApi; gridRef.current = params.api; kiblOddsCanvasState.updateBrowserTabTitle(); oddsGridApi.showLoadingOverlay(); }
  const setOptionsButtonHighlight = (params) => {return setLinkHilights(params);};
  
  // useEffectOnce(() => { fetch(`${kiblOddsApiUrl}bootstrap/column-defs-player-props`, composeKiblFetchOptions(kiblOddsApiToken, {init_request: 'column-defs'})).then(result => result.json()).then(columnDefs => setColumnDefs(columnDefs))}, []);
  // useEffectOnce(() => { fetch(`${kiblOddsApiUrl}bootstrap/canvas-player-props`, composeKiblFetchOptions(kiblOddsApiToken, composeCanvasParameters())).then(result => result.json()).then(rowData => setRowData(rowData))}, []);
  useEffectOnce(() => { fetch(`${kiblOddsApiUrl}${kiblOddsAPIColumnDefsUrl}`, composeKiblFetchOptions(kiblOddsApiToken, {init_request: 'column-defs'})).then(result => result.json()).then(columnDefs => setColumnDefs(columnDefs))}, []);
  useEffectOnce(() => { fetch(`${kiblOddsApiUrl}${kiblOddsAPICanvasUrl}`, composeKiblFetchOptions(kiblOddsApiToken, composeCanvasParameters())).then(result => result.json()).then(rowData => setRowData(rowData))}, []);
  // useEffectOnce(() => { window.addEventListener("beforeunload", alertUser); return () => { window.removeEventListener("beforeunload", alertUser); }; }, []); const alertUser = (e) => { e.preventDefault();  refreshView();};
  return (
    <div style={containerStyle}>
      <div className={navBarDisplay} style={sportNavStyle}>
        <span className='ag-kibl-nav-title'>Sports</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=TODAY', 'sportId=', 'leagueId=', 'scheduleRange=today')} className='ag-kibl-buttons-leagues'>Today</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=YESTERDAY', 'sportId=', 'leagueId=', 'scheduleRange=yesterday')} className='ag-kibl-buttons-leagues'>Yesterday</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=TOMORROW', 'sportId=', 'leagueId=', 'scheduleRange=tomorrow')} className='ag-kibl-buttons-leagues'>Tomorrow</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=FB', 'sportId=1', 'leagueId=', 'scheduleRange=upcoming')} className='ag-kibl-buttons-leagues'>Football</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=BK', 'sportId=3', 'leagueId=', 'scheduleRange=upcoming')} className='ag-kibl-buttons-leagues'>Basketball</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=HK', 'sportId=4', 'leagueId=', 'scheduleRange=upcoming')} className='ag-kibl-buttons-leagues'>Hockey</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=BB', 'sportId=2', 'leagueId=', 'scheduleRange=upcoming')} className='ag-kibl-buttons-leagues'>Baseball</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=TEN', 'sportId=5', 'leagueId=', 'scheduleRange=upcoming')} className='ag-kibl-buttons-leagues'>Tennis</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=GOLF', 'sportId=7', 'leagueId=', 'scheduleRange=upcoming')} className='ag-kibl-buttons-leagues'>Golf</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=FGT', 'sportId=8', 'leagueId=', 'scheduleRange=upcoming')} className='ag-kibl-buttons-leagues'>Fighting</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=AUTO', 'sportId=9', 'leagueId=', 'scheduleRange=upcoming')} className='ag-kibl-buttons-leagues'>Auto Racing</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=SOC', 'sportId=6', 'leagueId=', 'scheduleRange=upcoming', 'marketTypeId=2,3,7')} className='ag-kibl-buttons-leagues'>Soccer</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchToMyView('Tracking')} className='ag-kibl-buttons-leagues'>My Games</button>
      </div>
      <div className={navBarDisplay} style={favoNavStyle}>
        <span className='ag-kibl-nav-title'>Popular</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=NFL', 'sportId=1', 'leagueId=1', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>NFL</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=NFL', 'sportId=1', 'leagueId=2', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>NCAAF</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=NBA', 'sportId=3', 'leagueId=3', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>NBA</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=WNBA', 'sportId=3', 'leagueId=4', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>WNBA</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=MNCAAB', 'sportId=3', 'leagueId=5', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>MNCAAB</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=WNCAAB', 'sportId=3', 'leagueId=6', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>WNCAAB</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=NHL', 'sportId=4', 'leagueId=8', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>NHL</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=MLB', 'sportId=24', 'leagueId=7', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>MLB</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=PRO', 'sportId=', 'leagueId=1,3,4,7,8', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>PRO</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(false, 'currentTabName=NCAA', 'sportId=', 'leagueId=2,5,6,348,381', 'scheduleRange=upcoming')} className='ag-kibl-buttons-fav'>NCAA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        <span className='ag-kibl-nav-title'>Options</span>
        <button type="button" onClick={(e) => toggleOptions(e, 'sortByTime=toggle')} className={setOptionsButtonHighlight('sortByTime')} id="sortByTime">by time</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'lockToTodayOnly=toggle')} className={setOptionsButtonHighlight('lockToTodayOnly')} id="lockToTodayOnly">only todays</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'lockToGamesOnly=toggle')} className={setOptionsButtonHighlight('lockToGamesOnly')} id="lockToGamesOnly">only games</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'inGameMode=toggle')} className={setOptionsButtonHighlight('inGameMode')} id="inGameMode">in-game mode</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'showKiblIds=toggle')} className={setOptionsButtonHighlight('showKiblIds')} id="showKiblIds">kibl ids</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'showBoxScores=toggle')} className={setOptionsButtonHighlight('showBoxScores')} id="showBoxScores">linescores</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'autoRefresh=toggle')} className={setOptionsButtonHighlight('autoRefresh')} id="autoRefresh">15s</button>
        <button type="button" onClick={() => getSharableLink()} className='ag-kibl-buttons-toggles' id="sharableLink">share link</button>
        <button type="button" onClick={() => clearChanges('', true)} className='ag-kibl-buttons-toggles' id="clearAllChanges">clear all changes</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'theme=toggle')} className={setOptionsButtonHighlight('theme')} id="theme">dark mode</button>
      </div>
      <div className={navBarDisplay} style={segmentMarketNavStyle}>
        <span className='ag-kibl-nav-title'>Segments</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=FG', 'segmentId=1')} className='ag-kibl-buttons-segments'>FG</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=1H', 'segmentId=2,25')} className='ag-kibl-buttons-segments'>1H</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=2H', 'segmentId=3')} className='ag-kibl-buttons-segments'>2H</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=1Q', 'segmentId=4')} className='ag-kibl-buttons-segments'>1Q</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=2Q', 'segmentId=5')} className='ag-kibl-buttons-segments'>2Q</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=3Q', 'segmentId=6')} className='ag-kibl-buttons-segments'>3Q</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=4Q', 'segmentId=7')} className='ag-kibl-buttons-segments'>4Q</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=1P', 'segmentId=8')} className='ag-kibl-buttons-segments'>1P</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=2P', 'segmentId=9')} className='ag-kibl-buttons-segments'>2P</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=3P', 'segmentId=10')} className='ag-kibl-buttons-segments'>3P</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=F3', 'segmentId=26')} className='ag-kibl-buttons-segments'>F3</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchView(null, 'currentSegmentName=F7', 'segmentId=27')} className='ag-kibl-buttons-segments'>F7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
        <span className='ag-kibl-nav-title'>Markets</span>        
        <button type="button" onClick={() => switchMarketType('', 'm0')} className='ag-kibl-buttons-markets'>Combined</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchMarketType('Money', 'm1')} className='ag-kibl-buttons-markets'>Money</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchMarketType('Spread', 'm2')} className='ag-kibl-buttons-markets'>Spread</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchMarketType('Total', 'm3')} className='ag-kibl-buttons-markets'>Total</button><span className='ag-kibl-nav-pipe'>|</span>
        <button type="button" onClick={() => switchMarketType('Team Total', 'm4')} className='ag-kibl-buttons-markets'>Team Total</button>
        <button type="button" onClick={() => refreshView()} className='ag-kibl-buttons-toggles-important'>REFRESH VIEW</button>
      </div>
      <div className={playerPropBarDisplay} style={playerPropNavStyle}>
        <span className='ag-kibl-nav-note'>TOP = OVER / YES</span>
        <span className='ag-kibl-nav-note'>BOTTOM = UNDER / NO</span>
        <button type="button" onClick={(e) => toggleOptions(e, 'showAlternates=toggle')} className={setOptionsButtonHighlight('showAlternates')} id="showAlternates" style={playerPropToggles}>show alternates</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'inGameMode=toggle')} className={setOptionsButtonHighlight('inGameMode')} id="inGameMode" style={playerPropToggles}>in-game mode</button>
        <button type="button" onClick={(e) => toggleOptions(e, 'showKiblIds=toggle')} className={setOptionsButtonHighlight('showKiblIds')} id="showKiblIds" style={playerPropToggles}>kibl ids</button>
        <button type="button" onClick={() => refreshView()} className='ag-kibl-buttons-toggles-important'>REFRESH VIEW</button>
        
      </div>
    <div className={theme} style={gridStyle} id='kibl-canvas'>
      <AgGridReact
        gridId='kibl-odds-grid'
        rowHeight={37}
        rowData={rowData}
        rowSelection={'multiple'}
        columnDefs={columnDefs}
        columnHoverHighlight={true}
        suppressRowHoverHighlight={false}
        suppressRowClickSelection={true}
        suppressRowDeselection={true}
        suppressColumnVirtualisation={true}
        suppressRowVirtualisation={true}
        suppressMaxRenderedRowRestriction={true}
        onGridReady={onGridReady}
        maintainColumnOrder={true}
        groupMaintainOrder={true}
        ensureDomOrder={true}
        tooltipShowDelay={0}
        ref={gridRef}
        immutableData={true}
        suppressScrollOnNewData={true}
        onCellClicked={(e) => {
          let field = e.colDef.field;
          let cellId = e.event.srcElement.id.replace('-top', '').replace('-middle', '').replace('-bottom', '');

          if (field === 'igame') {
            e.node.setSelected(e.node.isSelected()? false : true);
          }
          else if (e.colDef.field.includes('b', 0)) {
            clearChanges(cellId, false, false, e);
          }
        }}        
        defaultColDef={defaultColDef}/>
    </div>
    </div>
  );
}



export default App;